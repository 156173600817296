<template>
  <v-card class="mx-0 pa-2" elevation="0" v-if="selected_contract !== null && contratLoaded" outlined>
    <v-row no-gutters>
      <v-row justify="space-between">
        <v-col cols="3">
          <h3 class="text--primary text--darken-2">{{ selected_contract.name }}
            <span class="grey--text font-italic">#{{ selected_contract.id }}</span></h3>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-btn @click="editContract()" v-if="isResponsableOrAdmin || hasPermission('change_contract')" text
                 color="primary">
            <v-icon>mdi-pencil</v-icon>
            Modifier
          </v-btn>
          <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_contract')" width="500">
            <template v-slot:activator="{on, attrs}">
              <v-btn text v-on="on" v-bind="attrs" color="error">
                <v-icon>mdi-trash-can-outline</v-icon>
                Supprimer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline red lighten-1 white--text">
                Supprimer le contrat
              </v-card-title>
              <v-card-text class="body-1 mt-2">
                Confirmer la suppression du contrat ?
              </v-card-text>
              <v-card-actions>
                <v-btn @click="deleteContract(selected_contract)" text color="red">Supprimer le contrat</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="selected_contract.archived === true">
        <span class="font-weight-bold orange--text">Ce contrat est archivé.</span>
      </v-col>
      <v-col cols="12">
        <h4>Équipements</h4>

          <v-data-iterator :footer-props="{itemsPerPageOptions: [4,8,12,-1]}" :items="selected_contract.assets" :items-per-page="4" >
            <template v-slot:default="{items}">
              <v-row>
              <v-col cols="6" xl="3" v-for="asset in items" :key="asset.id">
                <detail-card clickable
                             @click.native="$router.push('/assets/' + asset.id)"
                             icon="mdi-wrench" name="Équipement"
                             :information="asset !== null ? asset.name : 'Non défini'"></detail-card>
              </v-col>
              </v-row>
            </template>
          </v-data-iterator>

      </v-col>

      <v-col cols="6" xl="3">
        <detail-card icon="mdi-exclamation" name="Référence"
                     :information="selected_contract.reference !== null ? selected_contract.reference : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-calendar"
                     :name="selected_contract.type !== 'controle' ? 'Date de début' : 'Date de dernier contrôle'"
                     :information="selected_contract.date_start !== null ? getFormattedDate(selected_contract.date_start) : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-calendar"
                     :name="selected_contract.type !== 'controle' ? 'Date de fin' : 'Date du prochain contrôle'"
                     :information="selected_contract.date_end !== null ? getFormattedDate(selected_contract.date_end) : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <detail-card v-on="on" v-bind="attrs" icon="mdi-briefcase" name="Type"
                         :information="selected_contract.type !== null ? getContractType(selected_contract) : 'Non défini'"></detail-card>
          </template>
          <span>{{ selected_contract.type !== null ? getContractType(selected_contract) : 'Non défini' }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card v-if="selected_contract.type !== 'controle'" icon="mdi-barcode" name="Numéro de série"
                     :information="selected_contract.numero_serie !== null ? selected_contract.numero_serie : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-account-tie" name="Prestataire"
                     :clickable="selected_contract.prestataire !== null"
                     @click.native="showPrestataire()"
                     :information="selected_contract.prestataire !== null ? selected_contract.prestataire.raison_sociale : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-currency-eur" name="Coût du contrat"
                     :information="selected_contract.cost !== null ? selected_contract.cost + ' €' : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-calendar" name="Périodicité"
                     :information="selected_contract.periodicite !== null ? selected_contract.periodicite : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3">
        <detail-card icon="mdi-file-document-multiple" name="Renouvellement auto"
                     :information="selected_contract.automatic_renew === true ? 'Oui' : 'Non'"></detail-card>
      </v-col>
      <v-col cols="10">
        <p class="body-1"><strong>{{ selected_contract.attachments.length }} pièce<span
            v-if="selected_contract.attachments.length > 1">s</span> jointe<span
            v-if="selected_contract.attachments.length > 1">s</span> </strong></p>
      </v-col>
      <v-col cols="2">
        <v-dialog v-model="attachmentDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" elevation="2" color="primary" fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <PieceJointeForm @close-dialog="attachmentDialog = false;" type="contract"/>
        </v-dialog>
      </v-col>
      <v-col cols="6" xl="3" class="mb-2 pa-0 mini-card" v-for="attachment in selected_contract.attachments"
             :key="attachment.id">
        <v-row>
          <v-col cols="10" class="pa-0">
            <v-list-item two-line :href="attachment.file" target="_blank">
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ attachment.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getFilename(attachment.path) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col class="align-center justify-center pa-0" cols="2">
            <v-icon @click="deleteAttachment(attachment)">mdi-trash-can-outline</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row no-gutters class="py-4 ml-3">
      <v-col cols="12" sm="6">
        <v-btn v-if="isResponsableOrAdmin || hasPermission('add_entry')" @click="deleteOldEntries" color="grey"
               class="white--text darken-2">
          <v-icon>mdi-currency-eur</v-icon>
          Effacer les anciennes entrées financières
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" v-if="!selected_contract.archived">
        <v-dialog v-model="archivingDialog" max-width="600">
          <template v-slot:activator="{on, attrs}">
            <v-btn v-if="isResponsableOrAdmin || hasPermission('edit_contract')" v-on="on" v-bind="attrs"
                   color="orange" class="white--text">
              <v-icon>mdi-file-document</v-icon>
              Archiver le contrat
            </v-btn>
          </template>
          <v-card>
              <v-toolbar color="orange">
                <v-toolbar-title class="white--text">Confirmer l'archivage</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="mt-2">
                <p class="body-1">Confirmer l'archivage du contrat ?</p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="changeArchiveContract" color="orange" class="white--text">Archiver</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="6" v-else>
        <v-btn v-if="isResponsableOrAdmin || hasPermission('edit_contract')" @click="changeArchiveContract"
               color="orange" class="white--text">
          <v-icon>mdi-file-document</v-icon>
          Désarchiver le contrat
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        Commentaires
        <v-icon>mdi-message</v-icon>
      </v-tab>
      <v-tab>
        Périodes
        <v-icon>mdi-calendar</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Commentaires :contract="selected_contract" :key="selected_contract.id"/>
      </v-tab-item>
      <v-tab-item>
        <Periodes @refresh="refreshContract" :contract="selected_contract" :key="selected_contract.id"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  <v-card v-else-if="loading">
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DetailCard from "@/components/base/DetailCard";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {ContractMixin} from "@/mixins/contracts/ContractMixin";
import PieceJointeForm from "@/components/tickets/PieceJointeForm";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import Commentaires from "@/components/tickets/Commentaires";
import Periodes from "@/views/Periodes";

export default {
  name: "ContractView",
  components: {Periodes, DetailCard, PieceJointeForm, Commentaires},
  mixins: [DateUtilMixin, ContractMixin, AuthMixin],
  async mounted() {
    this.loading = true;
    let contractId = this.$route.params?.id;
    if (contractId !== undefined) {
      await this.$store.dispatch("contracts/getContractById", contractId);
      this.loading = false;
    }
    this.contratLoaded = true;
  },
  data() {
    return {
      attachmentDialog: false,
      deleteDialog: false,
      archivingDialog: false,
      contratLoaded: false,
      loading: false,
      tab: 0,
    }
  },
  computed: {
    selected_contract() {
      return this.$store.getters["contracts/getSelectedContract"];
    },

  },
  methods: {
    editContract() {
      this.$emit("edit-contract")
    },
    getFilename(path) {
      let elements = path.split("/")
      return elements[elements.length - 1]
    },
    generateFinancialEntries() {
      this.$store.dispatch("contracts/generateEntries", this.selected_contract);
    },
    deleteOldEntries() {
      this.$store.dispatch("contracts/deleteOldEntries", this.selected_contract);
    },
    async refreshContract() {
      let contractId = this.$route.params?.id;
      await this.$store.dispatch("contracts/getContractById", contractId);
    },
    async changeArchiveContract() {
      let contract = {
        id: this.selected_contract.id,
        archived: !this.selected_contract.archived,
      };
      this.archivingDialog = false;
      await this.$store.dispatch("contracts/putContract", contract);
      this.$store.dispatch("alerts/announceSuccess", "Contrat archivé.")
    },
    async deleteAttachment(attachment) {
      let result = await this.$store.dispatch("attachments/deleteContractAttachment", attachment);
      if (result === "success") {
        this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Document supprimé."});
      } else {
        this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue. Le document n'a peut être pas été correctement supprimé."
        });
      }
    },
    async deleteContract(contract) {
      await this.$store.dispatch("contracts/deleteContract", contract);
      this.deleteDialog = false;
    },
    async showPrestataire() {
      if (this.selected_contract.prestataire !== null) {
        let id = this.selected_contract.prestataire.representant_id;
        await this.$router.push(`/intervenants/${id}`);
      }
    }
  }
}
</script>

